<script setup lang="ts">
import { createSection, type HLResponseSectionTree } from '@app/state/response';
import Section from '@app/components/response/Section.vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import { Separator } from '@app/components/ui/separator'

defineProps<{
  sections: HLResponseSectionTree[]
  parentId?: string
}>()
</script>

<template>
  <Rows v-for="section in sections">
    <Section :key="section.id" :section="section" />
    <Separator @click="() => createSection(parentId)" class="my-6 cursor-pointer scale-150" label="+" />
  </Rows>
</template>

<style lang="scss" scoped></style>