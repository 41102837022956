<script async setup lang="ts">
import type { HLReferenceMeta } from '@Heirloom/common';
import { listReferences, references } from '@app/state/reference';
import { App, app, tab, tabs } from '@app/state/view';
import ReferenceCard from '@app/components/reference/ReferenceCard.vue';
import Grid from '@app/components/ui/flex/Grid.vue'
import { slug } from '@app/state/common';
import router from '@app/router';

listReferences()

const openReference = (reference: HLReferenceMeta) => {
  const exists = tabs.value.find(tab => tab.type === 'reference' && tab.id === reference.id)
  if (!exists) tabs.value.push(
    {
      name: reference.name.replace('.pdf', ''),
      type: 'reference',
      id: reference.id,
      reference
    }
  )
  router.push(`/t/${slug}/library/${reference.id}`)
}
</script>

<template>
  <Grid item-width="220px" gap="35" margin="20px 0" v-if="references.length > 0">
    <ReferenceCard @click="openReference(reference)" v-for="reference in references" :key="reference.id"
      :reference="reference" />
  </Grid>
</template>

<style scoped lang="scss">
</style>