<script lang="ts" setup>
import { Separator } from '@app/components/ui/separator'
import { Icon } from '@iconify/vue'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@app/components/ui/tooltip'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@app/components/ui/hover-card'
import ReferenceCard from '@app/components/reference/ReferenceCard.vue'
import SolicitationCard from '@app/components/solicitation/SolicitationCard.vue'
import Small from '@app/components/typography/Small.vue'
import Columns from '@app/components/ui/flex/Columns.vue'
import { ref, computed } from '@vue/reactivity'
import { bind, tenantName, userName } from '@app/state/auth';
import { App, app, min, tab, tabs, type Tab } from '@app/state/view';
import { connected, slug, socket, support } from '@app/state/common'
import { nextTick } from 'vue';
import { cool, status, warm } from '@app/state/scaling';
import router from '@app/router'

const showInfo = ref(false)

const openTab = (t: Tab) => {
  switch (t.type) {
    case 'reference':
      router.push(`/t/${slug}/library/${t.id}`)
      break;
    case 'solicitation':
      router.push(`/t/${slug}/write/${t.id}`)
      break;
    case 'chat':
      router.push(`/t/${slug}/chat/${t.id}`)
      break;
  }
}
const closeTab = (t: Tab) => {
  tabs.value = tabs.value.filter(tab => tab.id !== t.id)
  if (tab.value?.id === t.id) {
    tab.value = undefined
  }
  router.push(`/t/${slug}`)
}

interface AppTab {
  title: string,
  routeKey: string,
  icon: string,
  active: boolean,
  description: string,
  key: App,
  enabled: boolean
}
const apps = computed<AppTab[]>(() => [
  {
    title: "Write",
    routeKey: 'write',
    icon: "lucide:pen-tool",
    active: app.value === App.WRITE,
    description: "Analyze solicitations, draft proposals, and check compliance.",
    key: App.WRITE,
    enabled: true,
  },
  {
    title: "Library",
    routeKey: 'library',
    icon: "lucide:book-text",
    active: app.value === App.LIBRARY,
    description: "Upload past responses to train your personalized AI to write like you.",
    key: App.LIBRARY,
    enabled: true,
  },
  {
    title: "Chat",
    routeKey: 'chat',
    icon: "lucide:message-circle",
    active: app.value === App.CHAT,
    description: "Chat with all of your past performance using your custom AI.",
    key: App.CHAT,
    enabled: true
  },
  {
    title: "Feed",
    routeKey: 'feed',
    icon: "lucide:rss",
    active: app.value === App.FEED,
    description: "🚧 Coming soon: find new opportunities using our AI search.",
    key: App.FEED,
    enabled: false
  }
])

withDefaults(
  defineProps<{ isCollapsed: boolean }>(),
  { isCollapsed: false }
)

const openApp = (a: AppTab) => {
  if (!a.enabled) return;
  router.push(`/t/${slug}/${a.routeKey}`)
}
</script>

<template>
  <div class="spacer">
    <div :data-collapsed="isCollapsed" class="nav-container">
      <nav class="nav-links">
        <template v-for="(a, i) of apps">
          <Tooltip v-if="isCollapsed" :key="`collapsed-tooltip-${i}`" :delay-duration="0">
            <TooltipTrigger as-child>
              <a @click="openApp(a)" :class="{
                'nav-link': true,
                'nav-link-active': a.active && a.enabled,
                'nav-link-hoverable': !a.active && a.enabled
              }">
                <Icon :icon="a.icon" class="icon-size" />
                <span class="sr-only">{{ a.title }}</span>
              </a>
            </TooltipTrigger>

            <TooltipContent side="right" class="tooltip-content">
              {{ a.title }}
            </TooltipContent>
          </Tooltip>

          <Tooltip v-else :key="`tooltip-${i}`" :delay-duration="0">
            <TooltipTrigger as-child>
              <a @click="openApp(a)" :key="`tab-${i}`" :class="{
                'nav-link': true,
                'nav-link-active': a.active && a.enabled,
                'nav-link-hoverable': !a.active && a.enabled,
                'nav-link-collapsed': isCollapsed
              }">
                <Icon :icon="a.icon" class="icon-size" />
                {{ a.title }}
              </a>
            </TooltipTrigger>

            <TooltipContent side="right" class="tooltip-content">
              {{ a.description }}
            </TooltipContent>
          </Tooltip>
        </template>
      </nav>
      <Separator class="separator" />
      <nav class="nav-links tabs">
        <template v-for="(t, i) of tabs">

          <HoverCard v-if="isCollapsed" :key="`collapsed-tooltip-${i}`" :open-delay="0" :close-delay="0">
            <HoverCardTrigger as-child>
              <a @click="openTab(t)" :class="{
                'nav-link': true,
                'nav-link-active': app === App.TAB && t.id === tab?.id,
                'nav-link-hoverable': !(app === App.TAB && t.id === tab?.id)
              }">
                <Icon
                  :icon="t.type === 'reference' ? 'lucide:book-open' : t.type === 'chat' ? 'lucide:message-circle' : 'lucide:pencil'"
                  class="icon-size" />
                <span class="sr-only">{{ t.name }}</span>
              </a>
            </HoverCardTrigger>

            <HoverCardContent side="right" class="tooltip-content">
              <Columns v-if="t.type != 'chat'">
                <SolicitationCard v-if="t.type === 'solicitation'" :solicitation="t.solicitation" />
                <ReferenceCard v-if="t.type === 'reference'" :reference="t.reference" />
              </Columns>
              <span v-else>
                {{ t.name }}
              </span>
            </HoverCardContent>
          </HoverCard>

          <HoverCard v-else :key="`tooltip-${i}`" :open-delay="0" :close-delay="0">
            <HoverCardTrigger as-child>
              <a @click="openTab(t)" :key="`tab-${i}`" :class="{
                'nav-link': true,
                'nav-link-active': app === App.TAB && t.id === tab?.id,
                'nav-link-hoverable': !(app === App.TAB && t.id === tab?.id),
                'nav-link-collapsed': isCollapsed
              }">
                <Icon
                  :icon="t.type === 'reference' ? 'lucide:book-open' : t.type === 'chat' ? 'lucide:message-circle' : 'lucide:pencil'"
                  class="icon-size" />
                <span class="tab-name">{{ t.name }}</span>
              </a>
            </HoverCardTrigger>

            <HoverCardContent side="right" class="tooltip-content">
              <Columns v-if="t.type != 'chat'">
                <SolicitationCard v-if="t.type === 'solicitation'" :solicitation="t.solicitation" />
                <ReferenceCard v-if="t.type === 'reference'" :reference="t.reference" />
              </Columns>
              <span v-else>
                {{ t.name }}
              </span>
            </HoverCardContent>
          </HoverCard>
        </template>
      </nav>
    </div>
    <div v-if="!isCollapsed">
      <Separator />
      <nav class="nav-links">
        <Tooltip :delay-duration="0">
          <TooltipTrigger as-child>
            <a :class="{
              'nav-link': true,
              'nav-link-active': false,
              'nav-link-hoverable': false,
              'nav-link-collapsed': isCollapsed
            }">
              <Icon icon="lucide:circle-user" class="icon-size" />
              <span class="tab-name">{{ userName }}</span>
            </a>
          </TooltipTrigger>
          <TooltipContent side="right" class="tooltip-content">
            You are logged in as {{ userName }}
          </TooltipContent>
        </Tooltip>
        <Tooltip :delay-duration="0">
          <TooltipTrigger as-child>
            <a href="https://docs.govpro.ai" target="_blank" :class="{
              'nav-link': true,
              'nav-link-active': false,
              'nav-link-hoverable': true,
              'nav-link-collapsed': isCollapsed
            }">
              <Icon icon="lucide:circle-help" class="icon-size" />
              <span class="tab-name">Documentation</span>
            </a>
          </TooltipTrigger>
          <TooltipContent side="right" class="tooltip-content">
            View guides, tips, and more in the documentation.
          </TooltipContent>
        </Tooltip>
        <Tooltip :delay-duration="0">
          <TooltipTrigger as-child>
            <a href="mailto:josh@govpro.ai" :class="{
              'nav-link': true,
              'nav-link-active': false,
              'nav-link-hoverable': true,
              'nav-link-collapsed': isCollapsed
            }">
              <Icon icon="lucide:life-buoy" class="icon-size" />
              <span class="tab-name">Get Support</span>
            </a>
          </TooltipTrigger>
          <TooltipContent side="right" class="tooltip-content">
            Having trouble? Our team is here to help.
          </TooltipContent>
        </Tooltip>
      </nav>
    </div>
    <div v-else>
      <Separator />
      <nav class="nav-links">
        <a :class="{
          'nav-link': true,
          'nav-link-active': false,
          'nav-link-hoverable': false,
          'nav-link-collapsed': isCollapsed
        }">
          <Icon icon="lucide:circle-user" class="icon-size" />
        </a>
        <a href="https://docs.govpro.ai" target="_blank" :class="{
          'nav-link': true,
          'nav-link-active': false,
          'nav-link-hoverable': true,
          'nav-link-collapsed': isCollapsed
        }">
          <Icon icon="lucide:circle-help" class="icon-size" />
        </a>
        <a href="mailto:josh@govpro.ai" :class="{
          'nav-link': true,
          'nav-link-active': false,
          'nav-link-hoverable': true,
          'nav-link-collapsed': isCollapsed
        }">
          <Icon icon="lucide:life-buoy" class="icon-size" />
        </a>
      </nav>
    </div>
  </div>
</template>

<style scoped>
.nav-container {
  padding: 0.5rem 0;
}

.nav-links {
  display: grid;
  gap: 0.25rem;
  padding: 0 0.5rem;
}

.separator {
  margin: 0.5rem 0;
}

.nav-container[data-collapsed="true"] .nav-links {
  justify-content: center;
  padding: 0 0.5rem;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.375rem;
  text-decoration: none;
  font-size: var(--text-xs);
  padding: 0.5rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
}


.nav-link-active {
  background-color: hsl(var(--muted));
  color: hsl(var(--foreground));
}


.nav-link-active:hover {
  background-color: hsl(var(--muted));
  color: hsl(var(--foreground)) !important;
}


.nav-link-hoverable {
  background-color: transparent;
  color: hsl(var(--foreground));
}


.nav-link-hoverable:hover {
  background-color: hsl(var(--muted));
  color: hsl(var(--foreground));
}


.nav-link-collapsed {
  display: flex;
  justify-content: center;
}


.icon-size {
  width: 16px !important;
  height: 16px !important;
}

.tooltip-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
  border-radius: 0.375rem;

  span {
    font-size: var(--text-xs);
  }
}

.tooltip-label {
  margin-left: auto;
  color: hsl(var(--primary-foreground));
  opacity: .7;
}

.nav-link-label {
  margin-left: auto;
  color: hsl(var(--foreground));
}

.nav-link-active .nav-link-label {
  color: hsl(var(--foreground));
}

.tab-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: -webkit-fill-available;
}

.tabs {
  max-height: calc(100vh - 370px);
  overflow-y: auto;
}

.spacer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  height: calc(100% - 62px);
}
</style>
