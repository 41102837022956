<script async setup lang="ts">
import Icon from '@app/components/common/Icon.vue'
import { tenantName, userName } from '@app/state/auth';

</script>

<template>
  <div class="main" v-if="false">
  </div>
  <div class="empty" v-else>
    <Icon name="trees" color="grey" />
    You are now connected to {{ tenantName }}.
    <br>
    Welcome to GovPro Bid, {{ userName }}.
  </div>
</template>

<style scoped lang="scss">
.main {
  padding: 20px;
  display: flex;
  gap: 20px;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1px var(--primary-background-color);
  border-radius: var(--primary-border-radius);
  flex-wrap: wrap;
  align-content: flex-start;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
  width: 100%;
  color: var(--primary-font-color);
  flex-direction: column;
  text-align: center;
  img {
    width: 50px;
    margin-bottom: 12px;
  }
}
</style>