<script lang="ts" setup>
import { ref } from 'vue'
import AuthBox from '@app/components/auth/AuthBox.vue'
import Nav from '@app/components/core/Nav.vue'
import Loader from "@app/components/common/Loader.vue";
import Marshmallow from "@app/components/common/Marshmallow.vue";
import Right from "@app/components/common/Right.vue";
import ChatView from "@app/components/chat/View.vue";
import ChatConversation from "@app/components/chat/Conversation.vue";
import ReferenceToolbar from "@app/components/reference/Toolbar.vue";
import ReferenceGrid from "@app/components/reference/Grid.vue";
import ReferenceView from "@app/components/reference/View.vue";
import SolicitationToolbar from "@app/components/solicitation/Toolbar.vue";
import SolicitationGrid from "@app/components/solicitation/Grid.vue";
import SolicitationView from "@app/components/solicitation/View.vue";
import { Separator } from '@app/components/ui/separator'
import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from '@app/components/ui/tooltip'
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@app/components/ui/resizable'
import { Icon } from '@iconify/vue'
import { bind, tenantName, userName, isBound } from '@app/state/auth';
import { App, app, tab, tabs, type Tab } from '@app/state/view';
import { connected, socket, support } from '@app/state/common'
import { nextTick } from 'vue';
import { cool, status, warm } from '@app/state/scaling';

const showInfo = ref(false)

const openTab = (t: Tab) => {
  app.value = App.TAB
  tab.value = null
  nextTick(() => {
    tab.value = t
  })
}
const closeTab = (t: Tab) => {
  tabs.value = tabs.value.filter(tab => tab.id !== t.id)
  if (tab.value?.id === t.id) {
    tab.value = undefined
  }
  app.value = App.NONE
}

const isCollapsed = ref(false)
const collapse = () => isCollapsed.value = true
const expand = () => isCollapsed.value = false
</script>

<template>
  <TooltipProvider :delay-duration="0">
    <KeepAlive>
      <Suspense>
        <Marshmallow :ms="500">
          <AuthBox />
          <template v-if="isBound">
            <ResizablePanelGroup id="resize-panel-group-1" direction="horizontal" class="resizable-panel-group">
              <ResizablePanel id="sidebar" collapsible :default-size="128" :min-size="15" :max-size="20"
                :collapsed-size="4" :class="['resizable-panel', isCollapsed ? 'collapsed' : '']" @expand="expand"
                @collapse="collapse">
                <div :class="['tenant-info-container', isCollapsed ? 'collapsed' : '']">
                  <div class="tenant-info">
                    <Icon v-if="connected" class="tenant-icon" icon="lucide:radio" color="rgb(88 198 147)" />
                    <Icon v-else class="tenant-icon" icon="lucide:unplug" color="#eb6e93" />
                    <Tooltip>
                      <TooltipTrigger as-child>
                        <span class="tenant-name" v-if="!isCollapsed">{{ tenantName }}</span>
                      </TooltipTrigger>
                      <TooltipContent side="bottom" class="tooltip-content">
                        {{ tenantName }}
                      </TooltipContent>
                    </Tooltip>
                  </div>
                  <Tooltip v-if="status === 'warm'" :delay-duration="0">
                    <TooltipTrigger as-child>
                      <Icon @click="cool" class="tenant-icon" icon="lucide:flame" color="rgb(198 89 88)" />
                    </TooltipTrigger>

                    <TooltipContent side="right" class="tooltip-content">
                      The system is warm and will respond quickly.
                      <br>
                      You may click this to cool the system down.
                    </TooltipContent>
                  </Tooltip>
                  <Tooltip v-if="status === 'warming'" :delay-duration="0">
                    <TooltipTrigger as-child>
                      <Icon class="tenant-icon" icon="lucide:heater" color="rgb(198 89 88)" />
                    </TooltipTrigger>

                    <TooltipContent side="right" class="tooltip-content">
                      The system is warming up, please wait 1-2 minutes.
                    </TooltipContent>
                  </Tooltip>
                  <Tooltip v-if="status === 'cooling'" :delay-duration="0">
                    <TooltipTrigger as-child>
                      <Icon class="tenant-icon" icon="lucide:fan" color="rgb(88 178 198)" />
                    </TooltipTrigger>

                    <TooltipContent side="right" class="tooltip-content">
                      The system is cooling down.
                    </TooltipContent>
                  </Tooltip>
                  <Tooltip v-if="status === 'cold'" :delay-duration="0">
                    <TooltipTrigger as-child>
                      <Icon @click="warm" class="tenant-icon" icon="lucide:snowflake" color="rgb(88 178 198)" />
                    </TooltipTrigger>

                    <TooltipContent side="right" class="tooltip-content">
                      The system has not been used for some time and will respond slowly.
                      <br>
                      You may click this to warm the system up.
                    </TooltipContent>
                  </Tooltip>
                </div>
                <Separator />
                <Nav :is-collapsed="isCollapsed" />
              </ResizablePanel>
              <ResizableHandle id="sidebar-handle" with-handle />
              <ResizablePanel id="main" :default-size="1024">
                <template v-if="app === App.NONE">
                  <Right />
                </template>

                <template v-if="app === App.LIBRARY">
                  <ReferenceToolbar />
                  <ReferenceGrid />
                </template>

                <template v-if="app === App.WRITE">
                  <SolicitationToolbar />
                  <SolicitationGrid />
                </template>

                <template v-if="app === App.CHAT">
                  <ChatView />
                </template>

                <template v-if="app === App.TAB">
                  <template v-if="tab?.type === 'reference'">
                    <ReferenceView />
                  </template>

                  <template v-if="tab?.type === 'solicitation'">
                    <SolicitationView />
                  </template>

                  <template v-if="tab?.type === 'chat'">
                    <ChatConversation />
                  </template>
                </template>
              </ResizablePanel>
            </ResizablePanelGroup>
          </template>
        </Marshmallow>

        <template #fallback>
          <Loader />
        </template>
      </Suspense>
    </KeepAlive>
  </TooltipProvider>
</template>

<style scoped lang="scss">
.resizable-panel-group {
  height: 100%;
  display: flex;
  align-items: stretch;
}

.resizable-panel {
  transition: all 0.3s ease-in-out;
}

.resizable-panel.collapsed {
  min-width: 50px;
}

.tenant-info-container {
  display: flex;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
}

.tenant-info-container.collapsed {
  padding: 0;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}

.tenant-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  user-select: none;
  width: fit-content;
}

.tenant-icon {
  height: 0.9rem;
  width: 0.9rem;
}

.tenant-name {
  font-size: var(--text-xs);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  overflow: hidden;
  width: fit-content;
  max-width: 100%;
}
</style>