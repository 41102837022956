import { ref, computed } from "@vue/reactivity"
import type { HLResponseSection, Maybe } from "@Heirloom/common"
import { sock, BIND } from "@app/state/common"
import { solicitation } from "@app/state/solicitation"

//? Load
export const response = ref<HLResponseSection[]>([])
export const loadResponse = (id: string) => {
  if (id !== solicitation.value?.id) response.value = []
  sock.pub('response/load', { id })
}
BIND('response:load', () => s => response.value = s)

//? Delta
BIND('△:response', () => s => s.id === solicitation.value?.id ? loadResponse(s.id) : null)

export interface HLResponseSectionTree extends HLResponseSection {
  children: HLResponseSectionTree[]
}

//? Outline Tree
const tree = (sections: HLResponseSection[]): HLResponseSectionTree[] =>
  sections.map(s => ({
    ...s,
    children: tree(response.value.filter(c => c.parentId === s.id)).sort((a, b) => a.order - b.order)
  })).sort((a, b) => a.order - b.order)
  ;
export const responseTree = computed(() => tree(response.value.filter(s => !s.parentId)))

//? Edit Outline
export const editOutline = (instructions: string) => sock.pub('response/outline', { id: solicitation.value!.id, instructions })

//? Update Section
export const updateSection = (id: string, data: { title?: Maybe<string>, points?: Maybe<string>, order?: Maybe<number>, paragraphs?: Maybe<number> }) =>
  sock.pub('response/update', { id: solicitation.value!.id, sectionId: id, data })

//? Recalculate References
export const recalculateReferences = (id: string) =>
  sock.pub('response/references', { id: solicitation.value!.id, sectionId: id, references: null })

//? Select References
export const selectReferences = (id: string, references: { id: string }[]) =>
  sock.pub('response/references', { id: solicitation.value!.id, sectionId: id, references: references.map(r => r.id) })

//? Write Response
export const writeResponse = (id: string, instructions?: string) =>
  sock.pub('response/write', { id: solicitation.value!.id, sectionId: id, instructions })
export const writeFullResponse = () => {
  solicitation.value!.status = 'generating'
  response.value.forEach(s => writeResponse(s.id))
}

//? Export Response
export const exportResponse = () => sock.pub('response/export', { id: solicitation.value!.id })
BIND('response:export', () => ({ url }) => window.open(url, '_blank'))

//? Delete Section
export const deleteSection = (id: string) => sock.pub('response/delete', { id: solicitation.value!.id, sectionId: id })

//? Create Section
export const createSection = (parentId?: string) => sock.pub('response/create', { id: solicitation.value!.id, parentId })

export const downloadOutline = (): void => {
  const tree = responseTree.value
  const flattenAndSortTree = (tree: HLResponseSectionTree[]): HLResponseSection[] => {
    const flattened: HLResponseSection[] = []

    const traverse = (node: HLResponseSectionTree) => {
      flattened.push(node)
      if (node.children) node.children.sort((a, b) => a.order - b.order).forEach(traverse)
    }

    tree.map(traverse)
    return flattened.sort((a, b) => a.order - b.order)
  }

  const generateCSV = (tree: HLResponseSectionTree[]): string => {
    const flattened = flattenAndSortTree(tree)
    const headers = [
      'Order', 'Title', 'Obligations',
      'Reference 1', 'Reference 1 Body',
      'Reference 2', 'Reference 2 Body',
      'Reference 3', 'Reference 3 Body',
      'Response Body'
    ]

    const csvRows = [headers.join(',')]

    for (const section of flattened) {
      const row = [
        section.order,
        `"${section.title.replace(/"/g, '""')}"`,
        `"${section.points.replace(/"/g, '""')}"`,
        ...Array(3).fill(null).flatMap((_, i) => [
          `"${section.references[i]?.title.replace(/"/g, '""') || ''}"`,
          `"${section.references[i]?.markdown.replace(/"/g, '""') || ''}"`,
        ]),
        `"${section.paragraphs.map(p => p.markdown).join('\n\n').replace(/"/g, '""')}"`,
      ]

      csvRows.push(row.join(','))
    }

    return csvRows.join('\n')
  }

  const csv = generateCSV(tree)
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')

  link.href = URL.createObjectURL(blob)
  link.download = (solicitation.value!.title ?? 'outline').replace(/[^\w\s]/gi, '') + '.csv'
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}