<script async setup lang="ts">
import AddReference from '@app/components/reference/AddReference.vue';
import Grid from '@app/components/reference/Grid.vue'
import List from '@app/components/reference/List.vue'
import { Icon } from '@iconify/vue'
import { references } from '@app/state/reference';
import Rows from '@app/components/ui/flex/Rows.vue'
import Columns from '@app/components/ui/flex/Columns.vue'
import Header from '@app/components/ui/header/Header.vue'
import Large from '@app/components/typography/Large.vue'
import Button from '@app/components/ui/button/Button.vue'

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@app/components/ui/tabs"
</script>

<template>
  <Rows style="width: 100%" height="100vh">
    <Header>
      <Columns spread>
        <Columns left center gap="10">
          <Columns width="fit-content">
            <Large>
              Reference Library
            </Large>
          </Columns>
          <Columns width="fit-content">
            <Button variant="outline" size="sm">
              <template #icon>
                <Icon icon="lucide:shield-check" />
              </template>
              Secure
            </Button>
          </Columns>
        </Columns>
        <Columns width="200px">
          <AddReference />
        </Columns>
      </Columns>
    </Header>
    <Rows v-if="references.length <= 0" middle center flex="1" gap="20" class="text-center">
      <Icon icon="lucide:cloud-upload" height="4em" />
      Upload your past performance here by clicking "Add Reference" above.
    </Rows>
    <Rows v-else scroll padding="25px">
      <Rows flex="1">
        <Tabs default-value="grid">
          <TabsList>
            <TabsTrigger value="grid">
              <Icon icon="lucide:layout-grid" height="20px" />
            </TabsTrigger>
            <TabsTrigger value="list">
              <Icon icon="lucide:align-justify" height="20px" />
            </TabsTrigger>
          </TabsList>
          <TabsContent value="grid">
            <Grid />
          </TabsContent>
          <TabsContent value="list">
            <List />
          </TabsContent>
        </Tabs>
      </Rows>
    </Rows>
  </Rows>
</template>

<style scoped lang="scss"></style>