<script lang="ts" setup>
defineProps<{
  color?: 'default' | 'muted'
}>()
</script>

<template>
  <a :class="['btn-secondary', 'glow-on-hover', color ?? 'default']">
    <slot></slot>
  </a>
</template>

<style lang="scss">
.default {
  box-shadow: inset 0 0 0 1px var(--primary-color);
  background-color: #5b83ff20 !important;
  color: var(--primary-color) !important;
  &:hover {
    transition: .1s;
    background-color: #5b83ff40 !important;
  }
}

.muted {
  box-shadow: inset 0 0 0 1px #bccdff78;
  background-color: #bccdff20 !important;
  color: var(--primary-font-color) !important;
  &:hover {
    transition: .1s;
    background-color: #bccdff40 !important;
  }
}

.btn-secondary {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  user-select: none;
  vertical-align: top;
  text-align: center;
  height: 32px;
  border-radius: var(--primary-border-radius);
  gap: 8px;
  font-size: 14px;
  line-height: 20px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  font-weight: 500;
  width: fit-content;
  margin-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 5px;
  letter-spacing: .3px;
  transition: .1s;
  img {
    width: 13px;
    margin-top: -1px;
  }
}
</style>