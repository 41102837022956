<script async setup lang="ts">
import { watch } from 'vue';
import { ref } from '@vue/reactivity';
import type { Maybe } from '@Heirloom/common';
import Markdown from '@app/components/common/Markdown.vue';
import Badge from '@app/components/common/Badge.vue';
import Icon from '@app/components/common/Icon.vue';
import Loader from '@app/components/common/Loader.vue';
import { getSolicitationPage, solicitationPage } from '@app/state/solicitation';

const props = defineProps<{
  page: {
    id: string
    pageNumber: number
    markdown: Maybe<string>
    tags: string[]
  }
  total: number
}>()

const collapsed = ref(false)
const mode = ref<'image' | 'markdown'>('markdown')
const viewImage = async () => {
  mode.value = 'image'
  if (solicitationPage.value?.id !== props.page.id ) {
    await getSolicitationPage(props.page.id)
  }
}
watch(solicitationPage, () => {
  if (solicitationPage.value?.id !== props.page.id) {
    mode.value = 'markdown'
  }
})
</script>

<template>
  <div class="markdown section" :key="page.id">
    <div class="title" @click="collapsed = !collapsed">
      <Icon v-if="mode === 'markdown'" name="image" color="normal" @click.stop.prevent="viewImage" />
      <Icon v-else name="align-left" color="normal" @click.stop.prevent="mode = 'markdown'" />
      <span>Page {{ page.pageNumber + 1 }} / {{ total }}</span>
      <Badge class="page-tag" v-for="tag in page.tags">{{ tag }}</Badge>
    </div>
    <Markdown :markdown="page.markdown ?? ''" v-if="!collapsed && mode === 'markdown'" />
    <template v-if="!collapsed && mode === 'image'">
      <template v-if="solicitationPage?.id === page.id">
        <img class="page-img" :src="solicitationPage.image" crossorigin="anonymous" />
      </template>
      <template v-else>
        <Loader class="loader" />
        <div style="height: 400px"></div>
      </template>
    </template>
  </div>
</template>

<style scoped lang="scss">
.markdown {
  background-color: var(--secondary-background-color);
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--primary-background-color);
  padding: 12px;

  pre {
    color: var(--strong-font-color);
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-wrap;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: var(--primary-color);
    user-select: none;

    img {
      margin-top: -2px;
      margin-right: 6px;
    }

    .page-tag {
      text-transform: uppercase;
      height: 20px;
      top: -1px;
      margin-left: 8px;
      position: relative;
      color: var(--green);
      background-color: var(--green-bg);
      user-select: none;
    }
  }

  .page-img {
    width: 80%;
    margin: auto;
  }
}

.loader {
  margin-top: 140px;
}
</style>