<script async setup lang="ts">
import Question from '@app/components/solutioning/Question.vue';
import MinField from '@app/components/common/MinField.vue';
import { outlineSolicitation, solicitation } from '@app/state/solicitation';
import { loadSolutioning, solutioning } from '@app/state/solutioning';
import { ref, watch } from 'vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import Columns from '@app/components/ui/flex/Columns.vue';

watch(solicitation, sol => sol && loadSolutioning(sol.id))
if (solicitation.value) loadSolutioning(solicitation.value?.id)

const query = ref<string>('')
</script>

<template>
  <Columns maxWidth="900px" margin="10px auto">
    <Rows scroll height="calc(100vh - 115px)" padding="0 0 30px 0">
      <Question v-for="item in solutioning" :item="item" />
    </Rows>
  </Columns>
</template>

<style lang="scss" scoped>
.solutioning {
  display: flex;
  flex-direction: column;
  gap: 14px;

  overflow-y: auto;
  padding-bottom: 20px;
  padding-right: 10px;

  overflow: auto;
  height: calc(100vh - 175px) !important;
}
</style>