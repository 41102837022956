<script async setup lang="ts">
import { solicitation } from '@app/state/solicitation';
import { watch } from 'vue';
import MDSmore from '@app/components/response/MDSmore.vue';
import { loadResponse, responseTree } from '@app/state/response';
import Rows from '@app/components/ui/flex/Rows.vue';
import Columns from '@app/components/ui/flex/Columns.vue';

watch(solicitation, sol => sol && loadResponse(sol.id))
if (solicitation.value) loadResponse(solicitation.value?.id)
</script>

<template>
  <Columns maxWidth="900px" margin="25px auto">
    <Rows scroll height="calc(100vh - 124px)">
      <MDSmore :sections="responseTree" />
    </Rows>
  </Columns>
</template>

<style lang="scss" scoped></style>