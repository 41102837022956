<script async setup lang="ts">
import { ref } from '@vue/reactivity';
import Markdown from '@app/components/common/Markdown.vue';
import Icon from '@app/components/common/Icon.vue';
import Badge from '@app/components/common/Badge.vue';
import { solicitation, tagSection } from '@app/state/solicitation';
import { computed, nextTick, watch, getCurrentInstance } from 'vue';
import { green, greenBg, red, redBg } from '@app/state/view';

const props = defineProps<{
  id: string
  title: string
  markdown: string
  tags: string[]
  tokens: number
  score?: string | number | null
}>()

const collapsed = ref(props.tags.filter(
  tag => !(['none', 'other-requirements'].includes(tag.toLowerCase()))
).length == 0)

const copy = () => {
  navigator.clipboard.writeText(props.id)
}

const sectionTags = ref<string[]>(props.tags.map(tag => tag.toLowerCase().replace(/ /gim, '-')))
const isRequirement = computed(() => {
  return sectionTags.value.includes('requirements')
})
const isSubmissionInstructions = computed(() => {
  return sectionTags.value.includes('submission-instructions')
})
const isEvaluationCriteria = computed(() => {
  return sectionTags.value.includes('evaluation-criteria')
})

const tag = (tag: "requirements" | "submission-instructions" | "evaluation-criteria") => {
  const t = tag.toLowerCase().replace(/ /gim, '-')
  const tags = sectionTags.value
  tagSection(props.id, tag)
  sectionTags.value = []
  nextTick(() => { // this has been a bug since vue 2, sufficiently deeply nested arrays are not reactive
    if (tags.includes(t)) {
      sectionTags.value = tags.filter(tag => tag !== t)
      console.log(sectionTags.value)
    } else {
      tags.push(t)
      sectionTags.value = tags
      console.log(sectionTags.value)
    }
  })
}
</script>

<template>
  <div class="markdown section" :key="id">
    <div class="title" @click="collapsed = !collapsed">
      <div class="left">
        <div class="title-text">
          {{ title }} <span v-if="score">- {{ score }}</span>
        </div>
        <Badge class="badge" v-if="tokens > 2000" :color="red" :bg-color="redBg">
          {{ tokens }} tok
        </Badge>
        <Icon class="copy" name="copy" color="normal" @click.stop.prevent="copy" />
      </div>
      <div class="right">
        <Badge class="badge" @click.stop.prevent="tag('requirements')" :color="isRequirement ? red : '#979ade'"
          :bg-color="isRequirement ? redBg : '#d7ccff40'">
          C
        </Badge>
        <Badge class="badge" @click.stop.prevent="tag('submission-instructions')"
          :color="isSubmissionInstructions ? '#5b83ff' : '#979ade'"
          :bg-color="isSubmissionInstructions ? '#5b83ff40' : '#d7ccff40'">
          L
        </Badge>
        <Badge class="badge" @click.stop.prevent="tag('evaluation-criteria')"
          :color="isEvaluationCriteria ? green : '#979ade'" :bg-color="isEvaluationCriteria ? greenBg : '#d7ccff40'">
          M
        </Badge>
      </div>
    </div>
    <Markdown :markdown="markdown ?? ''" v-if="!collapsed" />
  </div>
</template>

<style scoped lang="scss">
.markdown {
  background-color: var(--secondary-background-color);
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--primary-background-color);
  padding: 12px;

  pre {
    color: var(--strong-font-color);
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-wrap;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: var(--primary-color);
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .copy {
      display: none;
    }

    &:hover {
      .copy {
        display: unset;
        opacity: 0.5;
        margin-left: 7px;
        width: 14px;

        &:hover {
          opacity: 1;
        }
      }
    }

    .right {
      margin-right: 8px;
      flex-basis: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 4px;
      user-select: none;
    }

    .left {
      max-width: calc(100% - 100px);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .title-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .badge {
      margin-top: 2px;
      margin-left: 4px;
    }
  }
}
</style>