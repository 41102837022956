import { ref } from "@vue/reactivity"
import type { HLReference, HLReferenceMeta, Maybe } from "@Heirloom/common"
import { sock, BIND } from "@app/state/common"
import { app, tab, tabs, App } from "@app/state/view"
import Logger from "@app/utils/logger"
const Log = new Logger("Reference", { bgColor: '#ccff99' })


//? Load
export const reference = ref<Maybe<HLReference>>()
export const loadReference = (id: string) => {
  if (id !== reference.value?.id) reference.value = null
  sock.pub('reference/load', { id })
}
BIND('reference:load', () => r => {
  r.sections = r.sections.sort((a, b) => a.sectionNumber - b.sectionNumber)
  reference.value = r
})

//? List
export const references = ref<HLReferenceMeta[]>([])
export const listReferences = () => sock.pub('reference/list', {})
BIND('reference:list', () => rs => references.value = rs.sort((a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf()))

//? Delta
BIND('△:reference', () => r => r.id === reference.value?.id ? loadReference(r.id) : listReferences())

//? Create
const files: Record<string, File> = {}
export const createReference = async (file: File) => {
  Log.info(`Creating reference for <${file.name}>`, file)
  sock.pub('reference/create', { filename: file.name })
  files[file.name] = file
}
BIND('reference:upload', ({ pub }) => ({ filename, url, id }) => {
  if (!url) throw new Error("Did not receive a presigned URL.")
  const file = files[filename]
  if (!file) throw new Error("Do not have file to upload.")

  Log.log(`Received presigned URL. Beginning upload of <${filename}>`)
  const xhr = new XMLHttpRequest()
  xhr.onload = () => {
    Log.success(`Successfully uploaded <${filename}>`)
    Log.info(`Parsing reference ${id} <${filename}>`)
    delete files[filename]
    pub('reference/parse', { id })
  }
  xhr.open('PUT', url)
  xhr.send(file)
  Log.log(`PUT <${filename}> → [${url.slice(0, 30)}...]`)
})

//? View page
export const getReferencePage = (pageId: string) => sock.pub('reference/page', { id: reference.value!.id, pageId })
export const referencePage = ref<Maybe<{ image: string, id: string }>>()
BIND('reference:page', ({ pub }) => ({ image, id }) => {
  referencePage.value = { image, id }
})

//? Delete
export const deleteReference = () => {
  if (!reference.value) return;
  sock.pub('reference/delete', { id: reference.value?.id })
  tabs.value = tabs.value.filter(tab => tab.id !== reference.value?.id)
  if (tab.value?.id === reference.value?.id) {
    tab.value = undefined
  }
  app.value = App.NONE
  reference.value = null
}
export const deleteReferenceById = (id: string) => {
  sock.pub('reference/delete', { id })
  tabs.value = tabs.value.filter(tab => tab.id !== id)
  if (tab.value?.id === id) tab.value = undefined
}

//? Disable
export const disableReference = (sectionId: string) => {
  if (!reference.value) return;
  sock.pub('reference/disable', { id: reference.value?.id, sectionId })
}

//? Download
export const downloadReference = () => {
  if (!reference.value) return;
  sock.pub('reference/download', { id: reference.value?.id })
}
BIND('reference:download', () => ({ url }) => {
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.click()
})

//? Chat
export const answer = ref<Maybe<string>>()
export const context = ref<HLReference['sections']>([])
export const chatReference = (message: string) => {
  if (!reference.value) return;
  answer.value = ''
  context.value = []
  sock.pub('reference/chat', { id: reference.value?.id, message })
}
BIND('reference:chat', () => ({ messagePart, sections }) => {
  if (messagePart) answer.value += messagePart
  if (sections) {
    context.value = []
    context.value.push(...sections)
  }
})
