<script async setup lang="ts">
import EditableText from '@app/components/common/EditableText.vue';
import Markdown from '@app/components/common/Markdown.vue';
import Smore from '@app/components/response/Smore.vue';
import Reference from '@app/components/response/Reference.vue';
import EditResponse from '@app/components/response/EditResponse.vue';
import { deleteSection, recalculateReferences, updateSection, type HLResponseSectionTree } from '@app/state/response';
import { ref } from '@vue/reactivity';
import Paragraph from '@app/components/typography/Paragraph.vue';
import Small from '@app/components/typography/Small.vue';
import Button from '@app/components/ui/button/Button.vue'
import Card from '@app/components/ui/card/Card.vue';


import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@app/components/ui/tabs"
import { Icon } from '@iconify/vue';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@app/components/ui/collapsible'
import Rows from '@app/components/ui/flex/Rows.vue'
import Columns from '@app/components/ui/flex/Columns.vue';

const props = defineProps<{
  section: HLResponseSectionTree
}>()

const n_paragraphs = ref(props.section.paragraphs.length + '')

const shiftUp = () => updateSection(props.section.id, {
  ...props.section,
  order: props.section.order - 1,
  paragraphs: undefined
})
const shiftDown = () => updateSection(props.section.id, {
  ...props.section,
  order: props.section.order + 1,
  paragraphs: undefined
})
const editTitle = () => updateSection(props.section.id, {
  ...props.section,
  title: props.section.title,
  paragraphs: undefined
})
const editPoints = () => updateSection(props.section.id, {
  ...props.section,
  points: props.section.points,
  paragraphs: undefined
})
const editParagraphs = () => updateSection(props.section.id, {
  ...props.section,
  paragraphs: parseInt(n_paragraphs.value),
})
</script>

<template>
  <Rows>
    <Card>
      <Columns padding="14px 15px 14px 13px">
        <Columns>
          <Collapsible type="single">
            <Columns flex="1">
              <CollapsibleTrigger :value="section.id" :key="section.id" class="text-left">
                <Columns>
                  <Columns flex="1">
                    <EditableText @submit="editTitle" v-model="section.title" />
                    <Icon icon="lucide:chevron" />
                  </Columns>
                  <Columns width="40px">
                    <Button variant="secondary" size="icon">
                      <Icon icon="lucide:chevrons-up-down" />
                    </Button>
                  </Columns>
                </Columns>
              </CollapsibleTrigger>
            </Columns>
            <CollapsibleContent :value="section.id">
              <Tabs default-value="outline" class="tabs-root">
                <Columns margin="5px 0 0 0">
                  <TabsList>
                    <TabsTrigger value="outline">
                      <Icon height="20px" icon="lucide:table-of-contents" />
                    </TabsTrigger>
                    <TabsTrigger value="content">
                      <Icon height="20px" icon="lucide:text" />
                    </TabsTrigger>
                  </TabsList>
                </Columns>

                <Rows>
                  <TabsContent value="outline">
                    <Paragraph>Obligations</Paragraph>
                    <Small>
                      <EditableText @submit="editPoints" v-model="section.points" multiline />
                    </Small>
                    <Paragraph>References</Paragraph>
                    <Reference v-for="reference in section.references" :reference="reference" />
                  </TabsContent>
                  <TabsContent value="content">
                    <Paragraph>Paragraphs</Paragraph>
                    <Small>
                      <Markdown v-for="paragraph in section.paragraphs" :key="paragraph.id"
                        :markdown="paragraph.markdown" />
                    </Small>
                  </TabsContent>
                </Rows>
              </Tabs>
            </CollapsibleContent>
          </Collapsible>
        </Columns>
      </Columns>
    </Card>
  </Rows>
</template>

<style scoped lang="scss"></style>