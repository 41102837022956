<script lang="ts" setup>
import { ref, computed } from '@vue/reactivity'
import Icon from '@app/components/common/Icon.vue'
import type defs from '@app/utils/icon-definitions'

const props = defineProps<{
  color?: string
  bgColor?: string
  icon?: string & keyof typeof defs
  label?: string
}>()

const color = computed(() => props.color || '#5b83ff')
const bgColor = computed(() => props.bgColor || '#5b83ff20')

const content = ref<HTMLDivElement>()
const text = computed(() => content.value?.innerText)
</script>

<template>
  <span :title="text">
    <Icon v-if="icon" :name="icon" color="white"></Icon>
    <div v-if="label" class="label">{{ label }}</div>
    <div ref="content" class="content">
      <slot></slot>
    </div>
  </span>
</template>

<style lang="scss" scoped>
img {
  max-height: 15px;
  margin-left: 2px;
  margin-right: 5px;
  vertical-align: text-top;
}

.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0025em;
  color: var(--primary-font-color);
  text-transform: capitalize;
}

span {
  background: v-bind('bgColor');
  opacity: 1.0;
  color: v-bind('color');
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0025em;
  padding: 2px 6px;
  gap: 6px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-style: normal;
  flex-shrink: 0;
  height: fit-content;
}

.content {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>