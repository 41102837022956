<script async setup lang="ts">
import type { HLSolicitationMeta } from '@Heirloom/common';
import { listSolicitations, solicitations } from '@app/state/solicitation';
import { App, app, tab, tabs } from '@app/state/view';
import SolicitationCard from '@app/components/solicitation/SolicitationCard.vue';
import Grid from '@app/components/ui/flex/Grid.vue'
import { slug } from '@app/state/common';
import router from '@app/router';

listSolicitations()

const openSolicitation = (solicitation: HLSolicitationMeta) => {
  const exists = tabs.value.find(tab => tab.type === 'solicitation' && tab.id === solicitation.id)
  if (!exists) tabs.value.push(
    {
      name: solicitation.name.replace('.pdf', ''),
      type: 'solicitation',
      id: solicitation.id,
      solicitation
    }
  )
  router.push(`/t/${slug}/write/${solicitation.id}`)
}
</script>

<template>
  <Grid item-width="220px" gap="35" margin="20px 0" v-if="solicitations.length > 0">
    <SolicitationCard @click="openSolicitation(solicitation)" v-for="solicitation in solicitations" :key="solicitation.id"
      :solicitation="solicitation" />
  </Grid>
</template>

<style scoped lang="scss"></style>