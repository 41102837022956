<script async setup lang="ts">
import Markdown from '@app/components/common/Markdown.vue';
import Badge from '@app/components/ui/badge/Badge.vue';
import Section from '@app/components/solicitation/Section.vue';
import Page from '@app/components/solicitation/Page.vue';
import MinField from '@app/components/common/MinField.vue';
import { ref } from '@vue/reactivity';
import { computed, watch } from 'vue';
import { chatSolicitation, solicitation, context as C, answer } from '@app/state/solicitation';
import type { Maybe } from '@Heirloom/common';
import { green, greenBg, red, redBg, segment, Segment } from '@app/state/view';
import Rows from '@app/components/ui/flex/Rows.vue';

const query = ref('')
const context = ref<{
  id: string,
  title: string,
  markdown: string,
  tags: string[],
  tokens: number
  score?: Maybe<string | number>
}[]>(
  solicitation.value?.sections.map(s => ({ ...s, tokens: Math.round(s.markdown.length / 4) }))
  ?? [])
const search = async () => {
  if (query.value) {
    chatSolicitation(query.value)
  } else {
    context.value = solicitation.value?.sections.map(s => ({ ...s, tokens: Math.round(s.markdown.length / 4) })) ?? []
    answer.value = ''
  }
}
watch(C, c => {
  const sections = c ?? solicitation.value?.sections ?? []
  context.value = sections.map(s => ({ ...s, tokens: Math.round(s.markdown.length / 4) }))
})
watch(solicitation, r => context.value = r?.sections.map(s => ({ ...s, tokens: Math.round(s.markdown.length / 4) })) ?? [])

const tokenCounts = computed(() => {
  const ret = {
    'total': 0,
    'requirements': 0,
    'submission-instructions': 0,
    'evaluation-criteria': 0
  }
  for (const section of context.value) {
    section.tags.map(tag => {
      if (tag === 'requirements') ret['requirements'] += section.tokens
      if (tag === 'submission-instructions') ret['submission-instructions'] += section.tokens
      if (tag === 'evaluation-criteria') ret['evaluation-criteria'] += section.tokens
    })
  }
  ret['total'] = ret['requirements'] + ret['submission-instructions'] + ret['evaluation-criteria']
  return ret
})
</script>

<template>
  <Rows v-if="solicitation" padding="25px 0">
    <div class="content tagging" v-if="segment === Segment.Markdown">
      <div class="markdown">
        <Markdown :markdown="solicitation.markdown ?? ''" />
      </div>
    </div>
    <div class="content tagging" v-if="segment === Segment.Sections">
      <div class="top">
        <MinField class="search" v-model="query" @submit="search" placeholder="Search sections" />
      </div>
      <div class="token-count">
        <Badge label="Total">{{ tokenCounts["total"] }} tokens</Badge>
        <Badge :color="red" :bg-color="redBg" label="Requirements">{{ tokenCounts["requirements"] }} tokens</Badge>
        <Badge label="Submission Instructions">{{tokenCounts["submission-instructions"] }} tokens</Badge>
        <Badge label="Evaluation Criteria">{{ tokenCounts["evaluation-criteria"] }} tokens</Badge>
      </div>
      <div class="markdown" v-if="answer">
        <Markdown :markdown="answer" />
      </div>
      <Section v-for="section in context" :key="section.id" :id="section.id" :title="section.title"
        :markdown="section.markdown" :tags="section.tags" :score="section.score" :tokens="section.tokens" />
    </div>
    <div class="content tagging" v-if="segment === Segment.Pages">
      <Page v-for="page in solicitation.pages" :key="page.id" :page="page" :total="solicitation.pages.length" />
    </div>
  </Rows>
</template>

<style lang="scss" scoped>
.segment-switch {
  display: flex;
  flex-direction: row;
  gap: 0px;
  background-color: var(--primary-background-color);
  border-radius: var(--primary-border-radius);
  justify-content: space-between;
  align-items: center;

  .segment {
    flex: 1;
    text-align: center;
    cursor: pointer;
    padding: 4px;
    font-size: 14px;
    box-shadow: inset 0 0 0 1px var(--primary-background-color-accent);
    color: var(--primary-font-color);

    &:hover {
      background-color: var(--secondary-background-color);
    }

    &.active {
      background-color: var(--secondary-background-color-accent);
      color: var(--primary-color);
    }
  }
}

.tagging {
  overflow: auto;
  height: calc(100vh - 125px) !important;
}

.content {
  height: -webkit-fill-available;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 10px;

  .token-count {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    user-select: none;
  }

  .top {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;

    .search {
      flex: 1;
    }
  }

  .markdown {
    background-color: var(--secondary-background-color);
    border-radius: 8px;
    box-shadow: 0 0 0 1px var(--primary-background-color);
    padding: 12px;

    pre {
      color: var(--strong-font-color);
      font-size: 14px;
      line-height: 1.5;
      white-space: pre-wrap;
    }
  }
}
</style>