<script async lang="ts" setup>
import { computed } from '@vue/reactivity'
import type { Maybe } from '@Heirloom/common';
import Icon from '@app/components/common/Icon.vue'

const props = defineProps<{
  modelValue: Maybe<string>
  onSubmit: () => void | any
  placeholder?: string
}>()

const emit = defineEmits(['update:modelValue'])

const value = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})
</script>

<template>
  <div class="min-field">
    <input type="text" v-model="value" @keyup.enter="onSubmit" :placeholder="placeholder" />
  </div>
</template>

<style scoped lang="scss">
.min-field {
  display: flex;
  align-items: stretch;
  border-radius: 4px;
  width: 100%;

  .icon {
    gap: 8px;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: -1px;
    margin-top: -1px;
    order: -1;
    width: 32px;
    height: 32px;
  }

  input {
    display: flex;
  height: 2.5rem;
  width: 100%;
  border-radius: var(--radius);
  border: 1px solid hsl(var(--input));
  background-color: hsl(var(--background));
  padding: 0.5rem 0.75rem;
  font-size: var(--text-sm);
  color: hsl(var(--foreground));
  }
  input::file-selector-button {
  border: 0;
  background-color: transparent;
  font-size: var(--text-sm);
  font-weight: 500;
}

input::placeholder {
  color: hsl(var(--muted-foreground));
}

input:focus-visible {
  outline: none;
  border: 1px solid hsl(var(--primary) / 0.9);
}

input:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
}
</style>