<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits,
} from 'radix-vue'
import { Icon } from '@iconify/vue'

const props = defineProps<DialogContentProps & { class?: HTMLAttributes['class'] }>()
const emits = defineEmits<DialogContentEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <DialogPortal>
    <DialogOverlay class="dialog-overlay" />
    <DialogContent v-bind="forwarded" :class="['dialog-content', props.class]">
      <slot />

      <DialogClose class="dialog-close">
        <Icon icon="radix-icons:cross-2" class="dialog-close-icon" />
        <span class="sr-only">Close</span>
      </DialogClose>
    </DialogContent>
  </DialogPortal>
</template>

<style scoped>
.dialog-overlay {
  position: fixed;
  inset: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.8);
}

.dialog-overlay[data-state="open"] {
  animation: fadeIn 200ms ease-out;
}

.dialog-overlay[data-state="closed"] {
  animation: fadeOut 200ms ease-in;
}

.dialog-content {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 50;
  display: grid;
  width: 100%;
  max-width: 32rem;
  transform: translate(-50%, -50%);
  gap: 1rem;
  border: 1px solid hsl(var(--border));
  background-color: hsl(var(--background));
  padding: 1.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: all 200ms;
  border-radius: var(--radius);
}

.dialog-content[data-state="open"] {
  animation: fadeInZoomIn 200ms ease-out;
}

.dialog-content[data-state="closed"] {
  animation: fadeOutZoomOut 200ms ease-in;
}

.dialog-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  outline: none;
  border: none;
  border-radius: 0.125rem;
  opacity: 0.7;
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
  transition: opacity 200ms;
}

.dialog-close:hover {
  opacity: 1;
}

.dialog-close:focus {
  outline: none;
  box-shadow: 0 0 0 2px hsl(var(--ring));
}

.dialog-close:disabled {
  pointer-events: none;
}

.dialog-close[data-state="open"] {
  background-color: hsl(var(--accent));
  color: hsl(var(--muted-foreground));
}

.dialog-close-icon {
  width: 1rem;
  outline: none;
  height: 1rem;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fadeInZoomIn {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fadeOutZoomOut {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }
}
</style>
