<script async setup lang="ts">
import Markdown from '@app/components/common/Markdown.vue';
import Marshmallow from '@app/components/common/Marshmallow.vue';
import Section from '@app/components/reference/Section.vue';
import Page from '@app/components/reference/Page.vue';
import { ref, watch, computed } from '@vue/reactivity';
import { support } from '@app/state/common';
import { tab } from '@app/state/view';
import {
  chatReference,
  deleteReference,
  downloadReference,
  loadReference,
  reference,
  context as C,
  answer,
  disableReference,
} from '@app/state/reference';
import type { HLReference, Maybe } from '@Heirloom/common';
import Large from '@app/components/typography/Large.vue'
import Columns from '@app/components/ui/flex/Columns.vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import Header from '@app/components/ui/header/Header.vue'
import { Icon } from '@iconify/vue'
import Button from '@app/components/ui/button/Button.vue'
import Textfield from '@app/components/ui/textfield/Textfield.vue';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@app/components/ui/tabs"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@app/components/ui/dropdown-menu'
import { nextTick } from 'vue';
import { TagsInput, TagsInputInput, TagsInputItem, TagsInputItemDelete, TagsInputItemText } from '@app/components/ui/tags-input'
import { ComboboxAnchor, ComboboxContent, ComboboxInput, ComboboxPortal, ComboboxRoot } from 'radix-vue'
import { CommandEmpty, CommandGroup, CommandItem, CommandList } from '@app/components/ui/command'

const modelValue = ref<string[]>([])
const open = ref(false)
const searchTerm = ref('')

if (tab.value) loadReference(tab.value.id)
watch(tab, (t) => {
  if (t) loadReference(t.id)
})

const query = ref('')

const uncheck = (sections: HLReference['sections']): (HLReference['sections'][number] & { checked: boolean })[] =>
  sections.map(s => ({ ...s, checked: false }))
const context = ref<(HLReference['sections'][number] & { checked: boolean })[]>(uncheck(reference.value?.sections ?? []))
const search = async () => {
  if (query.value) {
    chatReference(query.value)
  } else {
    context.value = uncheck(reference.value?.sections ?? [])
    answer.value = ''
  }
}
watch(C, c => context.value = uncheck(c ?? reference.value?.sections ?? []))
watch(reference, r => context.value = uncheck(r?.sections ?? []))

const mode = ref<'section' | 'page' | 'document'>('section')

const checkedSections = computed(() => context.value.filter(s => s.checked))
const disable = () => checkedSections.value.forEach(c => disableReference(c.id))
const lastChecked = ref<Maybe<number>>(null)
const lastLastChecked = ref<Maybe<number>>(null)
watch(lastChecked, (l, ll) => lastLastChecked.value = ll)
const shiftCheck = async () => {
  await nextTick()
  console.log('shift check', lastLastChecked.value, lastChecked.value)
  if (lastLastChecked.value == null || lastChecked.value == null) return;
  for (let i = lastLastChecked.value; i < lastChecked.value; i++) {
    console.log('check', i)
    context.value[i].checked = context.value[lastLastChecked.value].checked
  }
}
</script>

<template>
  <Rows height="100vh">
    <Rows v-if="reference" flex="1">
      <Header>
        <Columns spread center gap="10">
          <Large>
            {{ reference?.name.replace('.pdf', '') }}
          </Large>
          <Columns width="130px">
            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <Button variant="secondary">
                  <span class="mr-4">Actions</span>
                  <Icon icon="lucide:chevron-down" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuGroup>
                  <DropdownMenuItem @click="downloadReference">
                    <Icon icon="lucide:download" class="mr-2" />
                    <span>Download</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem @click="deleteReference">
                  <Icon icon="lucide:trash" class="text-red-500 mr-2" />
                  <span class="text-red-500">Delete</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </Columns>
        </Columns>
      </Header>
      <Rows middle>
        <Tabs v-model="mode" default-value="section" class="tabs-root">
          <Rows height="76px" padding="20px">
            <Columns spread gap="20">
              <Columns center middle gap="10" flex="5">
                <Columns width="fit-content">
                  <TabsList>
                    <TabsTrigger value="section">
                      <Icon height="20px" icon="lucide:rows-3" />
                    </TabsTrigger>
                    <TabsTrigger value="page">
                      <Icon height="20px" icon="lucide:file-stack" />
                    </TabsTrigger>
                    <TabsTrigger value="document">
                      <Icon height="20px" icon="lucide:letter-text" />
                    </TabsTrigger>
                  </TabsList>
                </Columns>
                <Columns flex="1">
                  <template v-if="mode === 'section'">
                    <Textfield borderless v-model="query" @submit="search" placeholder="Search sections..." />
                  </template>
                  <template v-else>
                  </template>
                </Columns>
              </Columns>
              <Columns flex="3" bottom center>
                <Columns width="fit-content" gap="10" v-if="checkedSections.length > 0">
                  <Button variant="secondary" @click="disable">
                    <template #icon>
                      <Icon icon="lucide:eye" />
                    </template>
                    <span class="mr-2">Enable</span>
                  </Button>
                  <Button variant="destructive" @click="disable">
                    <template #icon>
                      <Icon icon="lucide:eye-off" />
                    </template>
                    <span class="mr-2">Disable</span>
                  </Button>
                </Columns>
              </Columns>
            </Columns>
          </Rows>

          <Rows scroll flex="1" padding="0 20px">
            <TabsContent value="section" v-if="mode === 'section'">
              <Rows gap="10">
                <Section v-for="(section, i) in context" :key="section.id" :section="section" v-model="section.checked"
                  @check="lastChecked = i" @shift-check="shiftCheck" />
              </Rows>
            </TabsContent>
            <TabsContent value="page" v-if="mode === 'page'">
              <Rows gap="10">
                <Page v-for="page in reference.pages" :key="page.id" :page="page" :total="reference.pages.length" />
              </Rows>
            </TabsContent>
            <TabsContent value="document" v-if="mode === 'document'">
              <Markdown :markdown="context.map(c => c.markdown).join('\n\n') ?? ''" />
            </TabsContent>
          </Rows>
        </Tabs>
      </Rows>
    </Rows>

    <Rows v-if="!reference" middle center flex="1" gap="20" class="text-center">
      <Marshmallow :ms="500">
        <Icon icon="lucide:file-warning" height="4em" />
        <Large>An error occurred while loading this reference.</Large>
        <Button variant="link" class="support" @click="support">Please contact support if the issue persists.</Button>
      </Marshmallow>
    </Rows>
  </Rows>
</template>

<style scoped lang="scss">
.tags-input-root {
  flex-wrap: nowrap;
  overflow: auto;
}
</style>
