<script async setup lang="ts">
import Badge from '@app/components/ui/badge/Badge.vue';
import Marshmallow from '@app/components/common/Marshmallow.vue';
import PerlinNoise from '@app/components/common/PerlinNoise.vue';
import StepTag from '@app/components/solicitation/steps/Tag.vue'
import AddAttachment from '@app/components/solicitation/AddAttachment.vue'
import ButtonSecondary from '@app/components/common/ButtonSecondary.vue';
import StepSolutioning from '@app/components/solicitation/steps/Solutioning.vue'
import StepGenerate from '@app/components/solicitation/steps/Generate.vue'
import StepCompliance from '@app/components/solicitation/steps/Compliance.vue'
import StepReview from '@app/components/solicitation/steps/Review.vue'
import EditOutline from '@app/components/response/EditOutline.vue'
import { Loader2 } from 'lucide-vue-next'
import Header from '@app/components/ui/header/Header.vue'
import Paragraph from '@app/components/typography/Paragraph.vue';
import Large from '@app/components/typography/Large.vue'
import Columns from '@app/components/ui/flex/Columns.vue';
import Rows from '@app/components/ui/flex/Rows.vue';
import { Icon } from '@iconify/vue'
import Button from '@app/components/ui/button/Button.vue'
import Markdown from '@app/components/common/Markdown.vue';



import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@app/components/ui/avatar'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@app/components/ui/hover-card'



import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@app/components/ui/dialog'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@app/components/ui/popover'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@app/components/ui/dropdown-menu'
import Stepper from '@app/components/ui/steps/Stepper.vue';
import { ref } from '@vue/reactivity';
import { computed, watch } from 'vue';
import { support } from '@app/state/common';
import { green, greenBg, red, redBg, tab } from '@app/state/view';
import { deleteSolicitation, downloadSolicitation, extractSolicitation, loadSolicitation, outlineSolicitation, solicitation } from '@app/state/solicitation';
import { downloadOutline, exportResponse, response, writeFullResponse } from '@app/state/response';
import { checkCompliance, downloadCompliance } from '@app/state/compliance';

if (tab.value) loadSolicitation(tab.value.id)
watch(tab, (t) => {
  if (t) loadSolicitation(t.id)
})

enum Step {
  TAG = 1,
  SOLUTION = 2,
  GENERATE = 3,
  COMPLIANCE = 4,
  REVIEW = 5
}
const maxStep = computed<Step>(() => {
  if (solicitation.value?.status === 'created') return Step.TAG
  if (solicitation.value?.status === 'processing') return Step.TAG
  if (solicitation.value?.status === 'parsed') return Step.TAG
  if (solicitation.value?.status === 'deleted') return Step.TAG
  if (solicitation.value?.status === 'tagging') return Step.TAG
  if (solicitation.value?.status === 'extraction') return Step.TAG
  if (solicitation.value?.status === 'solutioning') return Step.SOLUTION
  if (solicitation.value?.status === 'generating') return Step.GENERATE
  if (solicitation.value?.status === 'outlining') return Step.GENERATE
  if (solicitation.value?.status === 'writing') return Step.GENERATE
  if (solicitation.value?.status === 'checking') return Step.COMPLIANCE
  if (solicitation.value?.status === 'compliance') return Step.COMPLIANCE
  if (solicitation.value?.status === 'complete') return Step.REVIEW
  return Step.TAG
})
const step = ref<Step>(maxStep.value)

const showDelete = ref(false)
const hasResponse = computed(() => response.value.every(
  s => s.paragraphs.filter(p => p.markdown.trim().length === 0).length === 0
))

const interimSteps = ["created", "processing", "extraction", "generating", "checking"]
watch(solicitation, async (cur, old) => {
  if (!cur) return;
  if (!old) return (step.value = maxStep.value);
  if (interimSteps.includes(cur.status)) return;
  if (cur.status === old.status) return;
  if (cur.status === 'solutioning') {
    step.value = Step.SOLUTION
  }
  if (cur.status === 'outlining') {
    step.value = Step.GENERATE
  }
  if (cur.status === 'compliance') {
    step.value = Step.COMPLIANCE
  }
  if (cur.status === 'complete') {
    step.value = Step.REVIEW
  }
})
</script>

<template>
  <Rows v-if="solicitation">
    <Rows gap="0">
      <Header>
        <Columns left center gap="10">
          <Columns flex="1">
            <Large>
              {{ solicitation.name.replace('.pdf', '') }}
              <HoverCard>
                <HoverCardTrigger as-child>
                  <Button variant="link">
                    <Icon icon="lucide:info" color="grey" />
                  </Button>
                </HoverCardTrigger>
                <HoverCardContent class="w-screen">
                  <Rows>
                    <Large>
                      {{ solicitation.title }}
                    </Large>
                    <Rows>
                      <Rows margin="0 0 10px 0">
                        <Paragraph>
                          {{ solicitation.bluf }}
                        </Paragraph>
                      </Rows>
                      <Rows margin="0 0 10px 0">
                        <template v-for="meta in solicitation.meta">
                          <Badge v-if="meta.value && meta.value !== 'Not provided'" :key="meta.id" :label="meta.key"
                            variant="secondary">
                            {{ meta.value }}
                          </Badge>
                        </template>
                      </Rows>
                      <Rows>
                        <template v-for="attachment in solicitation.attachments">
                          <Badge v-if="attachment" :key="attachment" variant="secondary">
                            {{ attachment.split('/').pop() }}
                          </Badge>
                        </template>
                      </Rows>
                    </Rows>
                  </Rows>
                </HoverCardContent>
              </HoverCard>
            </Large>
          </Columns>

          <template v-if="!(interimSteps.includes(solicitation.status))">
            <Columns width="250px" v-if="step === Step.TAG" gap="10">
              <DropdownMenu>
                <DropdownMenuTrigger as-child>
                  <Button variant="secondary">
                    <span class="mr-4">Actions</span>
                    <Icon icon="lucide:chevron-down" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem>
                      <Icon icon="lucide:plus" class="mr-2" />
                      <span>
                        <AddAttachment />
                      </span>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <Icon icon="lucide:pen" class="mr-2" />
                      <span>Action</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <Icon icon="lucide:pen" class="mr-2" />
                      <span>Action</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem>
                      <Icon icon="lucide:trash" class="text-red-500 mr-2" />
                      <span class="text-red-500">Delete Solicitation</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
              <Button variant="default" @click.stop.prevent="extractSolicitation">
                <template #icon>
                  <Icon icon="lucide:pencil" />
                </template>
                Solutioning
              </Button>
            </Columns>

            <Columns width="240px" v-if="step === Step.SOLUTION" gap="10">
              <DropdownMenu>
                <DropdownMenuTrigger as-child>
                  <Button variant="secondary">
                    <span class="mr-4">Actions</span>
                    <Icon icon="lucide:chevron-down" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem>
                      <Icon icon="lucide:pen" class="mr-2" />
                      <span>Action</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <Icon icon="lucide:pen" class="mr-2" />
                      <span>Action</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <Icon icon="lucide:pen" class="mr-2" />
                      <span>Action</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem>
                      <Icon icon="lucide:trash" class="text-red-500 mr-2" />
                      <span class="text-red-500">Delete Solicitation</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
              <Button @click.stop.prevent="outlineSolicitation">
                <template #icon>
                  <Icon icon="lucide:pen" />
                </template>
                Outline
              </Button>
            </Columns>

            <Columns width="300px" v-if="step === Step.GENERATE" gap="10">
              <DropdownMenu>
                <DropdownMenuTrigger as-child>
                  <Button variant="secondary">
                    <span class="mr-4">Actions</span>
                    <Icon icon="lucide:chevron-down" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem>
                      <Icon icon="lucide:brain" class="mr-2" />
                      <Dialog>
                        <DialogTrigger as-child>
                          View instructions
                        </DialogTrigger>
                        <DialogContent>
                          <DialogHeader class="p-6 pb-0">
                            <DialogTitle>Instructions</DialogTitle>
                            <DialogDescription>
                              AI analysis of the best proposal structure.
                            </DialogDescription>
                          </DialogHeader>
                          <Markdown class="md" :markdown="solicitation?.structure ?? 'No analysis made (yet).'" />
                        </DialogContent>
                      </Dialog>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <Icon icon="lucide:rotate-cw" class="mr-2" />
                      <span>
                        <EditOutline />
                      </span>
                    </DropdownMenuItem>
                    <DropdownMenuItem @click.stop.prevent="downloadOutline">
                      <Icon icon="lucide:file-spreadsheet" class="mr-2" />
                      <span>Export CSV</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem @click.stop.prevent="writeFullResponse">
                      <Icon icon="lucide:pen" class="mr-2" />
                      <span>{{ hasResponse ? 'Rewrite' : 'Write' }}</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem>
                      <Icon icon="lucide:trash" class="text-red-500 mr-2" />
                      <span class="text-red-500">Delete Solicitation</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
              <Button @click.stop.prevent="checkCompliance" :disabled="!hasResponse">
                <template #icon>
                  <Icon icon="lucide:list-checks" />
                </template>
                Check Compliance
              </Button>
            </Columns>

            <Columns width="260px" v-if="step === Step.COMPLIANCE" gap="10">
              <DropdownMenu>
                <DropdownMenuTrigger as-child>
                  <Button variant="secondary">
                    <span class="mr-4">Actions</span>
                    <Icon icon="lucide:chevron-down" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem>
                      <Icon icon="lucide:pen" class="mr-2" />
                      <span>Action</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <Icon icon="lucide:pen" class="mr-2" />
                      <span>Action</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem>
                      <Icon icon="lucide:trash" class="text-red-500 mr-2" />
                      <span class="text-red-500">Delete Solicitation</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>

              <Button @click.stop.prevent="exportResponse" :disabled="!hasResponse">
                <template #icon>
                  <Icon icon="lucide:file-down" />
                </template>
                Export Draft
              </Button>
            </Columns>
          </template>

          <Columns v-else width="130px">
            <Button disabled variant="default">
              <Loader2 class="w-4 h-4 mr-2 animate-spin" />
              Please wait
            </Button>
          </Columns>
        </Columns>
      </Header>
      <Stepper :steps="[
        { num: Step.TAG, name: 'Tagging' },
        { num: Step.SOLUTION, name: 'Solutioning' },
        { num: Step.GENERATE, name: 'Generate' },
        { num: Step.COMPLIANCE, name: 'Compliance' },
        { num: Step.REVIEW, name: 'Review' }
      ]" v-model="step" :completed="maxStep - 1" />
    </Rows>

    <Rows padding="0 20px">
      <StepTag v-if="step === Step.TAG" />
      <StepSolutioning v-if="step === Step.SOLUTION" />
      <StepGenerate v-if="step === Step.GENERATE" />
      <StepCompliance v-if="step === Step.COMPLIANCE" />
      <StepReview v-if="step === Step.REVIEW" />
    </Rows>
  </Rows>

  <Rows v-else middle center flex="1" gap="20" class="text-center">
    <Marshmallow :ms="200">
      <Icon icon="lucide:file-warning" height="4em" />
      <Large>An error occurred while loading this opportunity.</Large>
      <Button variant="link" class="support" @click="support">Please contact support if the issue persists.</Button>
    </Marshmallow>
  </Rows>
</template>

<style scoped lang="scss">
.md {
  height: calc(100vh - 100px);
  overflow: auto;
}

.text-lg {
  width: calc(100% - 30px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>